import React, { Component } from "react";
import { Row, Col, Form, Button, Spinner, Alert, Container } from "react-bootstrap";
import "../assets/style/login.css";
import { submitLogin } from "../includes/HelperLogin";
import auth from "../Auth";

class Login extends Component {
  state = {
    logingin: false,
    isInvalidLogin: -1,
    invalidLoginMessage: "",
  };

  constructor() {
    super();
    this.handleLogin = this.handleLogin.bind(this);
  }

  async handleLogin(event) {
    event.preventDefault();
    const user_email = event.target.elements.user_email.value;
    const user_password = event.target.elements.user_password.value;

    this.setState({ logingin: true });
    const response = await submitLogin(user_email, user_password);
    this.setState({ logingin: false });

    if (response.status === 0) {
      this.setState({ isInvalidLogin: 1, invalidLoginMessage: response.error });
    } else {
      auth.login(response, () => {
        this.props.history.push("dashboard");
      });
    }
  }

  render() {
    const site_tag =
      window.location.hostname === "admin.palletiq.com" || window.location.hostname === "admin-dev.palletiq.com"
        ? "PALLETIQ"
        : window.location.hostname === "admin.myemptyshelves.com" ||
          window.location.hostname === "admin.emptyshelves.com"
          ? "EMPTYSHELVES"
          : window.location.hostname === "admin.bookteriors.com"
            ? "BOOKTERIORS"
            : window.location.hostname === "admin.brandonsbooks.com"
              ? "BRANDONS BOOKS"
              : window.location.hostname === "admin.blueinkbooks.com"
                ? "BLUEINKBOOKS"
                : "MBB";
    return (
      <React.Fragment>
        <Container fluid={true}>
          <Row>
            <Col md={12}>
              <div className="div-form-container">
                <div>
                  <h2>{site_tag} ADMIN</h2>
                </div>
                <div style={{ borderBottom: "1px solid #ddd" }}>
                  <h4>LOGIN</h4>
                </div>

                {this.state.isInvalidLogin === 1 && (
                  <div style={{ marginTop: "20px" }}>
                    <Alert variant="danger">
                      <span>{this.state.invalidLoginMessage}</span>
                    </Alert>
                  </div>
                )}
                <div style={{ marginTop: "35px" }}>
                  <Form onSubmit={this.handleLogin}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        style={{ textAlign: "center" }}
                        type="text"
                        name="user_email"
                        placeholder="Enter email"
                      />
                    </Form.Group>

                    <Form.Group style={{ marginTop: "30px" }} controlId="formBasicPassword">
                      <Form.Control
                        style={{ textAlign: "center" }}
                        type="password"
                        name="user_password"
                        placeholder="Password"
                      />
                    </Form.Group>

                    {this.state.logingin ? (
                      <Spinner style={{ marginTop: "33px" }} animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      <Button variant="primary" type="submit" style={{ marginTop: "30px" }}>
                        Submit
                      </Button>
                    )}
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Login;
