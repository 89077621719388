import React, { Component } from "react";
import { Row, Col, InputGroup, Badge } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

class ItemSearch extends Component {
  constructor() {
    super();
    this.handleAsinSelect = this.handleAsinSelect.bind(this);
  }
  getRemainingItems = () => {
    let itemsRemaining = 0;
    this.props.activeItems.forEach(activeItem => {
      let itemRemaining = 0;
      if (activeItem.assignedToBoxes > 0) itemRemaining = activeItem.quantity - activeItem.assignedToBoxes;
      else itemRemaining = activeItem.quantity;
      itemsRemaining += itemRemaining;
    });
    return itemsRemaining;
  };

  async handleAsinSelect(selectedAsin) {
    /**
     * When all the items belonging to a specific ASIN have been scanned, the textbox is automatically cleared.
     */
    this.props.handleAsinSelect(selectedAsin, () => {
      if (selectedAsin.length > 0) {
        console.log("Executed after!!!");
        if (selectedAsin[0].assignedToBoxes === selectedAsin[0].quantity) this.typeahead.getInstance().clear();
      }
    });
  }

  render() {
    return (
      <InputGroup className="mb-3">
        <InputGroup.Prepend>
          <InputGroup.Text>ASIN</InputGroup.Text>
        </InputGroup.Prepend>
        <Typeahead
          id="noOutlineInputGroup"
          className="noOutlineInputGroup"
          filterBy={this.props.filterByCallback}
          labelKey="Asin"
          selectHintOnEnter={true}
          ref={typeahead => (this.typeahead = typeahead)}
          disabled={this.props.loading_item}
          onChange={this.handleAsinSelect}
          options={this.props.activeItems.filter(activeItem => activeItem.assignedToBoxes !== activeItem.quantity)}
          placeholder="Enter ASIN/ISBN or Item Name or SKU..."
          renderMenuItemChildren={option => (
            <Row className="itemDropDownList">
              <Col md={2}>
                <img
                  style={{ width: "70px" }}
                  alt={option.name}
                  src={"http://images.amazon.com/images/P/" + option.Asin + ".01._SCMZZZZZZZ_.jpg"}
                />
                <Badge variant="dark" className="badgeCountItems">
                  {option.assignedToBoxes > 0 ? option.quantity - option.assignedToBoxes : option.quantity}
                </Badge>
                <Badge variant="primary" className="badgeFulfillmentCenter">
                  {option.DestinationFulfillmentCenterId}
                </Badge>
              </Col>
              <Col md={10}>
                <div className="itemDropDownListRight">
                  <div>
                    <strong>ASIN: {option.Asin}</strong>
                  </div>
                  <div>
                    <small>FNSKU: {option.Fnsku}</small>
                  </div>
                  <div>
                    <small>SellerSKU: {option.SKU}</small>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        />
        <InputGroup.Append>
          <InputGroup.Text>{this.getRemainingItems()} remaining</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    );
  }
}

export default ItemSearch;
