import React, { Component } from "react";
import Select from "react-select";
import { Row, Col, Form, Modal, Button, Spinner } from "react-bootstrap";

const csvOptions = [
  "Title",
  "Sales Rank",
  "eScore",
  "Weight",
  "Lowest Used Price",
  "Lowest New Price",
  "New Buy Box",
  "Used Buy Box",
  "Amazon Price",
  "FBA Target Price",
  "FBA Estimated Profit",
  "FBA Decision",
  "MP Target Price",
  "MP Estimated Profit",
  "MP Price",
  "MP Decision",
  "MF Target Price",
  "MF Estimated Profit",
  "MF Decision",
  "SBYB Price",
  "Ziffit Price",
  "BS Highest Winner",
  "BS Highest Price",
  "Module Winner",
  "HL Winner",
  "is Restricted",
  "Lowest Sold",
  "20th Sold",
  "Median Sold",
  "80th Sold",
  "Highest Sold",
  "Product Group",
];

class NewQueue extends Component {
  state = {
    currentCSVFile: null,
    checkedCsvFields: csvOptions,
    selectedTriggerBatch: null,
    selectedShippingPlan: null,
  };

  constructor() {
    super();
    this.onFormSubmitAddTrigger = this.onFormSubmitAddTrigger.bind(this);
    this.onChangeCSVFile = this.onChangeCSVFile.bind(this);
  }

  handleOpen() { }

  async onFormSubmitAddTrigger(event) {
    event.preventDefault();

    if (this.state.selectedTriggerBatch !== null && this.state.selectedShippingPlan !== null) {
      const reevaluateQueueName = event.target.elements.reevaluateQueueName.value;
      const reevaluateTriggerBatchId = this.state.selectedTriggerBatch.value;
      const shippingPlanId = this.state.selectedShippingPlan.value;
      const reevaluateFrequency = event.target.elements.frequency.value;
      const recipientEmail = event.target.elements.recipientEmail.value;
      const isLive = event.target.elements.isLive.value;
      const csvFields = JSON.stringify(this.state.checkedCsvFields);

      this.props.onFormSubmitAddQueue(
        reevaluateQueueName,
        reevaluateTriggerBatchId,
        shippingPlanId,
        reevaluateFrequency,
        this.state.currentCSVFile,
        recipientEmail,
        csvFields,
        isLive
      );
    } else {
      alert(`Pick a trigger batch & shipping plan first`);
    }
  }

  onChangeCSVFile = (e) => {
    if (e.target.files[0] !== undefined) {
      this.setState({ currentCSVFile: e.target.files[0] });
    }
  };

  render() {
    const ddOptions = this.props.triggerBatches.map((triggerBatch) => {
      return {
        label: triggerBatch.id + " - " + triggerBatch.team_name + " - " + triggerBatch.name,
        value: triggerBatch.id,
      };
    });

    const ddOptionsShippingPlans = this.props.shippingPlans.map((shippingPlan) => {
      return {
        label: shippingPlan.id + " - " + shippingPlan.name,
        value: shippingPlan.id,
      };
    });

    return (
      <Modal
        onEntered={this.handleOpen}
        show={this.props.showAddModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Form onSubmit={this.onFormSubmitAddTrigger}>
          <Modal.Header closeButton>
            <Form.Control
              required
              style={{ paddingLeft: "0" }}
              type="text"
              name="reevaluateQueueName"
              className="input-text-trigger-name"
              placeholder="Enter Queue Name Here"
            />
          </Modal.Header>
          <Modal.Body>
            <div style={{ fontSize: "13px", fontWeight: "500" }}>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={12}>
                      <div>
                        <Form.Group controlId="formAudioFile">
                          <Form.Label>Choose .csv File</Form.Label>
                          <Form.Control
                            onChange={this.onChangeCSVFile}
                            type="file"
                            name="csvFile"
                            placeholder="Choose .csv File"
                          />
                        </Form.Group>
                      </div>
                      <div></div>
                    </Col>
                  </Row>

                  <div>
                    <Select
                      value={this.state.selectedTriggerBatch}
                      placeholder="Pick a trigger batch to run this with"
                      onChange={(selectedTriggerBatch) => {
                        this.setState({ selectedTriggerBatch });
                        //this.handleChange;
                      }}
                      options={ddOptions}
                    />
                  </div>

                  <div style={{ marginTop: "20px" }}>
                    <Select
                      value={this.state.selectedShippingPlan}
                      placeholder="Pick a shipping plan to run this with"
                      onChange={(selectedShippingPlan) => {
                        this.setState({ selectedShippingPlan });
                        //this.handleChange;
                      }}
                      options={ddOptionsShippingPlans}
                    />
                  </div>

                  <Row style={{ marginTop: "25px" }}>
                    <Col md={4}>
                      <Form.Check
                        style={{ marginBottom: "10px", color: "red" }}
                        type="checkbox"
                        defaultChecked={true}
                        id={"checkuncheckfields"}
                        onChange={(e) => {
                          if (e.target.checked) this.setState({ checkedCsvFields: csvOptions });
                          else this.setState({ checkedCsvFields: [] });
                        }}
                        label={"Check/Uncheck All"}
                      />
                    </Col>

                    {csvOptions.map((vendor) => {
                      return (
                        <Col md={4}>
                          <Form.Check
                            style={{ marginBottom: "10px" }}
                            type="checkbox"
                            defaultChecked={true}
                            checked={this.state.checkedCsvFields.indexOf(vendor) > -1}
                            id={"check-" + vendor}
                            onChange={(e) => {
                              const checkedCsvFields = [...this.state.checkedCsvFields];
                              const vIndex = checkedCsvFields.indexOf(e.target.value);

                              if (e.target.checked) checkedCsvFields.push(e.target.value);
                              else checkedCsvFields.splice(vIndex, 1);
                              this.setState({ checkedCsvFields });
                            }}
                            label={vendor}
                            value={vendor}
                          />
                        </Col>
                      );
                    })}
                  </Row>

                  <Row style={{ marginTop: "10px", borderTop: "1px solid #ddd", paddingTop: "15px" }}>
                    <Col md={2}>
                      <Form.Group controlId="formFrequency">
                        <Form.Label>Use Live?</Form.Label>
                        <Form.Control as="select" name="isLive" defaultValue="0">
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={5}>
                      <Form.Group controlId="formFrequency">
                        <Form.Label>Queue Frequency</Form.Label>
                        <Form.Control as="select" name="frequency">
                          <option value="once">Just this once</option>
                          <option value="everyday">Every Day</option>
                          <option value="everysat">Every Saturday</option>
                          <option value="everytueandsat">Every Tuesday & Saturday</option>
                          <option value="alternatesat">Alternate Saturday</option>
                          <option value="firsteverymonth">1st of Every Month</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={5}>
                      <Form.Group controlId="formAudioFile">
                        <Form.Label>Recipient email address</Form.Label>
                        <Form.Control required type="email" name="recipientEmail" />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.props.isProcessingApi === true ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default NewQueue;
