import React, { Component } from "react";
import { Container, Row, Col, Button, Spinner, InputGroup, FormControl, Form } from "react-bootstrap";
import { reconfigTB, validateSBYBEmail } from "../../../includes/HelperTriggerBatches";
import ColorTextPicker from "../../Others/ColorTextPicker";
import ColorTextPickerPlain from "../../Others/ColorTextPickerPlain";

class SBYBTrigger extends Component {
  state = {
    isTriggerEnabled: false,
    config_wholesale: [],
    targetDiff: 0,
    thisTabIndex: -1,
    isValidSBYB: null,
    reconfigInProgress: false,
    validatingSBYBEmail: false,
    customText: "",
    customColor: "",
    sbybEmail: "",
    minPrice: 0,
    riskAdjustmentPer: 100,
  };

  constructor(props) {
    super(props);
    this.handleReConfig = this.handleReConfig.bind(this);
    this.toggleCurrent = this.toggleCurrent.bind(this);
    this.handleUpdateSBYBEmail = this.handleUpdateSBYBEmail.bind(this);
  }

  componentDidMount() {
    /**
     * This is what executes before everything when the page loads.
     * What we're doing here:
     * 1. calling API to load/set triggers
     */

    this.setState({
      triggerBatchId: this.props.triggerBatchId,
      sbybEmail: this.props.admin_data.sbyb_email,
      isValidSBYB: this.props.admin_data.is_valid_sbyb,
    });

    if ("conf_wholesale" in this.props.batchConfiguration && this.props.batchConfiguration.conf_wholesale !== null) {
      const config_wholesale = JSON.parse(this.props.batchConfiguration.conf_wholesale);
      this.setState({ config_wholesale });

      console.log(config_wholesale);

      const index = config_wholesale.findIndex(function (module) {
        return module.module_name === "SBYB";
      });
      this.setState({ thisTabIndex: index });

      const current_config = config_wholesale[index];
      console.log(current_config);

      if ("enabled" in current_config) {
        this.setState({
          isTriggerEnabled: current_config.enabled,
          targetDiff: current_config.target_diff,
          sbybEmail: "sbybEmail" in current_config ? current_config.sbybEmail : this.props.admin_data.sbyb_email,
          customText: "text" in current_config ? current_config.text : "",
          customColor: "color" in current_config ? current_config.color : "",
          minPrice: "min_price" in current_config ? current_config.min_price : 0,
          riskAdjustmentPer: "risk_adjustment_per" in current_config ? current_config.risk_adjustment_per : 100,
        });
      }
    }
  }

  async handleReConfig(color, text) {
    let config_wholesale_clone = [...this.state.config_wholesale];
    const index = config_wholesale_clone.findIndex(function (module) {
      return module.module_name === "SBYB";
    });
    this.setState({ reconfigInProgress: true });
    const targetDiff = this.state.targetDiff;
    const sbybEmail = this.state.sbybEmail;

    if (targetDiff >= 0) {
      config_wholesale_clone[index].enabled = true;
      config_wholesale_clone[index].sbybEmail = sbybEmail;
      config_wholesale_clone[index].target_diff = parseFloat(targetDiff);
      config_wholesale_clone[index].min_price = parseFloat(this.state.minPrice);
      config_wholesale_clone[index].risk_adjustment_per = parseInt(this.state.riskAdjustmentPer);
      if (undefined !== color && undefined !== text) {
        config_wholesale_clone[index].color = color;
        config_wholesale_clone[index].text = text;
      }

      this.setState({ isTriggerEnabled: true });
    } else {
      config_wholesale_clone[index].enabled = false;
      this.setState({ isTriggerEnabled: false });
    }
    this.props.updateTBConfig("conf_wholesale", config_wholesale_clone);
    await reconfigTB(
      this.props.admin_data,
      this.props.triggerBatchId,
      "conf_wholesale",
      JSON.stringify(config_wholesale_clone)
    );
    this.setState({ reconfigInProgress: false });
  }

  async handleUpdateSBYBEmail() {
    this.setState({ validatingSBYBEmail: true });
    const res = await validateSBYBEmail(this.props.admin_data, this.state.sbybEmail);
    this.setState({ validatingSBYBEmail: false });
    if (res === false || ("status" in res && res.status === false)) {
      this.setState({ isValidSBYB: 0 });
    } else this.setState({ isValidSBYB: 1 });

    // const response = await fetch(
    //   `https://www.sellbackyourbook.com/API/bulkAPI.php?isbn=1118615611&emailAddress=${this.state.sbybEmail}&method=priceItem&autoAdd=true&appVersion=piqv1`
    // );
    // if (response.status === 200) {
    //   const data = await response.json();
    //   console.log(data);
    // } else return false;
  }

  async toggleCurrent(e) {
    this.setState({ isTriggerEnabled: e.target.checked, reconfigInProgress: true });

    let config_wholesale_clone = [...this.state.config_wholesale];
    const index = config_wholesale_clone.findIndex(function (module) {
      return module.module_name === "SBYB";
    });
    config_wholesale_clone[index].enabled = e.target.checked;
    this.setState({ isTriggerEnabled: e.target.checked });

    this.props.updateTBConfig("conf_wholesale", config_wholesale_clone);

    await reconfigTB(
      this.props.admin_data,
      this.props.triggerBatchId,
      "conf_wholesale",
      JSON.stringify(config_wholesale_clone)
    );
    this.setState({ reconfigInProgress: false });
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid={true} style={{ padding: "0" }}>
          <Row style={{ marginTop: "0px" }}>
            <Col md={12}>
              <table style={{ width: "auto", marginBottom: "40px" }}>
                <tbody>
                  <tr>
                    <td style={{ padding: "10px 10px 10px 0" }}>
                      <div className="mod-setting-name">Enable SBYB?</div>
                      <div className="mod-setting-desc">
                        Toggle ON to pull SBYB prices and include to routing decision.
                      </div>
                    </td>
                    <td style={{ padding: "10px 0 10px 20px" }}>
                      <Form.Check
                        onChange={this.toggleCurrent}
                        checked={this.state.isTriggerEnabled}
                        type="switch"
                        disabled={this.state.reconfigInProgress}
                        id="enable-trigger"
                        label={this.state.isTriggerEnabled ? "YES" : "NO"}
                      />
                    </td>
                  </tr>
                  {this.state.isTriggerEnabled === true && (
                    <React.Fragment>
                      {this.state.thisTabIndex > 0 && (
                        <tr>
                          <td style={{ padding: "10px 10px 10px 0" }}>
                            <div className="mod-setting-name">Price Bump</div>
                            <div className="mod-setting-desc"></div>
                          </td>

                          <td style={{ padding: "10px 0 10px 20px" }}>
                            <InputGroup id="ig_asin" style={{ width: "250px" }}>
                              <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                value={this.state.targetDiff}
                                onChange={(e) => {
                                  this.setState({ targetDiff: e.target.value });
                                }}
                                autoComplete="off"
                                id="targetDiffInput"
                                placeholder="Difference"
                                type="number"
                                aria-describedby="basic-addon1"
                              />
                              <InputGroup.Append id="btn_asin_search">
                                <Button onClick={this.handleReConfig} disabled={this.state.reconfigInProgress}>
                                  {this.state.reconfigInProgress === true ? (
                                    <Spinner style={{ width: "20px", height: "20px" }} animation="border" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </Spinner>
                                  ) : (
                                    <span>Save</span>
                                  )}
                                </Button>
                              </InputGroup.Append>
                            </InputGroup>
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td style={{ padding: "10px 10px 10px 0" }}>
                          <div className="mod-setting-name">SBYB Authorized Email</div>
                          <div className="mod-setting-desc"></div>
                        </td>
                        <td style={{ padding: "10px 0 10px 20px" }}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <div>
                              <InputGroup id="ig_asin" style={{ width: "300px" }}>
                                <FormControl
                                  value={this.state.sbybEmail}
                                  onChange={(e) => {
                                    this.setState({ sbybEmail: e.target.value });
                                  }}
                                  autoComplete="off"
                                  id="targetDiffInput"
                                  placeholder="email@domain.com"
                                  type="email"
                                  aria-describedby="basic-addon1"
                                />
                                <InputGroup.Append id="btn_asin_search">
                                  <Button
                                    onClick={() => {
                                      this.handleReConfig();
                                      this.handleUpdateSBYBEmail();
                                    }}
                                    disabled={this.state.validatingSBYBEmail}
                                  >
                                    {this.state.validatingSBYBEmail === true ? (
                                      <Spinner
                                        style={{ width: "20px", height: "20px" }}
                                        animation="border"
                                        role="status"
                                      >
                                        <span className="sr-only">Loading...</span>
                                      </Spinner>
                                    ) : (
                                      <span>Save</span>
                                    )}
                                  </Button>
                                </InputGroup.Append>
                              </InputGroup>
                            </div>

                            <div style={{ marginLeft: "20px" }}>
                              <i
                                style={{
                                  paddingTop: "4px",
                                  fontSize: "20px",
                                  color:
                                    this.state.isValidSBYB === 1
                                      ? `green`
                                      : this.state.isValidSBYB === 0
                                      ? `red`
                                      : `grey`,
                                }}
                                className={
                                  this.state.isValidSBYB === 1
                                    ? `fa fa-check-circle`
                                    : this.state.isValidSBYB === 0
                                    ? `fa fa-times-circle`
                                    : `fa fa-question-circle`
                                }
                              />
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td style={{ padding: "10px 10px 10px 0" }}>
                          <div className="mod-setting-name">Custom Display Color & Text</div>
                          <div className="mod-setting-desc"></div>
                        </td>
                        <td style={{ padding: "10px 0 10px 20px" }}>
                          <ColorTextPickerPlain
                            customColor={this.state.customColor}
                            customText={this.state.customText}
                            updateCustomTextColor={async (color, text) => {
                              await this.handleReConfig(color, text);
                            }}
                            updatingCustomTextColor={this.state.reconfigInProgress}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td style={{ padding: "10px 10px 10px 0" }}>
                          <div className="mod-setting-name">Accept only if price greater than</div>
                          <div className="mod-setting-desc"></div>
                        </td>
                        <td style={{ padding: "10px 0 10px 20px" }}>
                          <InputGroup id="ig_asin" style={{ width: "250px" }}>
                            <InputGroup.Prepend>
                              <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              style={{ width: "50px" }}
                              value={this.state.minPrice}
                              onChange={(e) => {
                                this.setState({ minPrice: e.target.value });
                              }}
                              autoComplete="off"
                              id="minPriceInput"
                              placeholder="Min"
                              type="number"
                              aria-describedby="basic-addon1"
                            />
                            <InputGroup.Append id="btn_asin_search">
                              <Button onClick={this.handleReConfig} disabled={this.state.reconfigInProgress}>
                                {this.state.reconfigInProgress === true ? (
                                  <Spinner style={{ width: "20px", height: "20px" }} animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </Spinner>
                                ) : (
                                  <span>Save</span>
                                )}
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </td>
                      </tr>

                      <tr>
                        <td style={{ padding: "10px 10px 10px 0" }}>
                          <div className="mod-setting-name">
                            Risk Adjustment{" "}
                            <span className="mod-setting-tag-new">
                              <sup>(NEW)</sup>
                            </span>
                          </div>
                          <div className="mod-setting-desc"> Display vendor prices at X % of the quoted SBYB Price</div>
                        </td>
                        <td style={{ padding: "10px 0 10px 20px" }}>
                          <InputGroup id="ig_asin" style={{ width: "250px" }}>
                            <InputGroup.Prepend>
                              <InputGroup.Text id="inputGroupPrepend">%</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              value={this.state.riskAdjustmentPer}
                              onChange={(e) => {
                                this.setState({ riskAdjustmentPer: e.target.value });
                              }}
                              onKeyDown={(e) => {
                                if (e.key === ".") e.preventDefault();
                              }}
                              disabled={this.state.reconfigInProgress}
                              autoComplete="off"
                              id="riskAdjustmentPer"
                              placeholder="Enter a number"
                              type="number"
                              aria-describedby="basic-addon1"
                            />
                            <InputGroup.Append id="btn_asin_search">
                              <Button onClick={this.handleReConfig} disabled={this.state.reconfigInProgress}>
                                {this.state.reconfigInProgress === true ? (
                                  <Spinner style={{ width: "20px", height: "20px" }} animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </Spinner>
                                ) : (
                                  <span>Save</span>
                                )}
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </td>
                      </tr>
                    </React.Fragment>
                  )}
                </tbody>
              </table>

              {/* <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <Form.Check
                    onChange={this.toggleCurrent}
                    checked={this.state.isTriggerEnabled}
                    type="switch"
                    disabled={this.state.reconfigInProgress}
                    id="enable-trigger"
                    label="Enable?"
                  />
                </div>

                <div style={{ marginLeft: "30px", display: "flex", alignItems: "center" }}>
                  {this.state.isTriggerEnabled === true && this.state.thisTabIndex > 0 && (
                    <div>
                      <div>Price Bump: </div>
                      <div style={{ marginLeft: "10px" }}>
                        <InputGroup id="ig_asin" style={{ width: "250px" }}>
                          <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            value={this.state.targetDiff}
                            onChange={(e) => {
                              this.setState({ targetDiff: e.target.value });
                            }}
                            autoComplete="off"
                            id="targetDiffInput"
                            placeholder="Difference"
                            type="number"
                            aria-describedby="basic-addon1"
                          />
                          <InputGroup.Append id="btn_asin_search">
                            <Button onClick={this.handleReConfig} disabled={this.state.reconfigInProgress}>
                              {this.state.reconfigInProgress === true ? (
                                <Spinner style={{ width: "20px", height: "20px" }} animation="border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </Spinner>
                              ) : (
                                <span>Save</span>
                              )}
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>
                    </div>
                  )}
                  <div style={{ marginLeft: "30px" }}>SBYB Authorized Email: </div>
                  <div style={{ marginLeft: "10px" }}></div>

                  <div style={{ marginLeft: "15px" }}>
                    <i
                      style={{
                        paddingTop: "4px",
                        fontSize: "20px",
                        color: this.state.isValidSBYB === 1 ? `green` : this.state.isValidSBYB === 0 ? `red` : `grey`,
                      }}
                      className={
                        this.state.isValidSBYB === 1
                          ? `fa fa-check-circle`
                          : this.state.isValidSBYB === 0
                          ? `fa fa-times-circle`
                          : `fa fa-question-circle`
                      }
                    />
                  </div>
                </div>
              </div> */}
            </Col>
          </Row>

          {/* <Row style={{ marginTop: "20px", borderTop: "1px solid #ddd", paddingTop: "20px" }}>
            <Col md={12}>
              {this.state.isTriggerEnabled === true && this.props.admin_data.premium_level === 1 && (
                <div style={{ marginBottom: "20px" }}>
                  <ColorTextPicker
                    customColor={this.state.customColor}
                    customText={this.state.customText}
                    updateCustomTextColor={async (color, text) => {
                      await this.handleReConfig(color, text);
                    }}
                    updatingCustomTextColor={this.state.reconfigInProgress}
                  />
                </div>
              )}

              <div style={{ display: "flex", marginTop: "20px" }}>
                <div style={{ paddingTop: "5px", marginRight: "10px" }}>Accept only if price is greater than </div>
                <div></div>
              </div>
            </Col>
          </Row> */}
        </Container>
      </React.Fragment>
    );
  }
}

export default SBYBTrigger;
